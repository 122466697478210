* {
  margin: 0;
  border: 0;
  box-sizing: border-box;
}

.Header {
  padding: 2rem;
}

.App {
  padding: 2rem;
}

.Container_big_padding {
  padding-left: 1rem !important;
  padding-right: 1rem !important;
  padding-bottom: 4rem;
  display: flex;
  flex-direction: column;
  max-width: 2000px !important;
  margin: 0 auto !important;
}

.Container {
  padding: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.Container_small {
  padding: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  max-width: 2000px !important;
  width: 98% !important;
}

.Container_zero_padding {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.Container_zero_padding_not_center {
  display: flex;
  flex-direction: column;
}

.Container_small_right {
  padding: 0,5rem;
  display: flex;
  justify-content: right;
  align-items: right;
}

.Container_small_left {
  padding: 0,5rem;
  display: flex;
  justify-content: left;
  align-items: left;
}

.Container_small_middle {
  padding: 0,5rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Container_middle {
  padding: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Container_Modal {
  padding: 2rem;
  display: flex;
  flex-direction: column;
  max-width: 2000px !important;
  width: 98% !important;
  margin: 0 auto !important;
}

.Container_Modal_Message_mobile {
  padding-top: 5px;
  padding-right: 69%;
  display: flex;
  flex-direction: column;
}

.Container_buyCode {
  padding: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Container_Text_Center {
  padding: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
}

.Container_bottom {
  padding: 2rem;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
}

#card {
  outline: 5px solid #F17221;
  transform: none;
}

#card-button {
  margin-top: auto; /* Schiebt den Button nach unten */
}

.form-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

#card-selected{
  background-color: #000000;
  color: #FFFFFF;
  transform: none;
}

#card-selected-productname{
  background-color: #000000;
  color: #FFFFFF;
  transform: none;
  margin-top: 0px;
  padding-top: 2px;
}

#card-unselected-productname{
  transform: none;
  margin-top: 0px;
  padding-top: 2px;
}

#menu_padding{
  padding-left: 4.59rem;
  padding-right: 4.59rem;
}

#item-center{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
}

#modal_center{
  align-items: center;
}

html {
  scroll-behavior: smooth;
}

#br_replacer{
  margin: 10px 0 10px 0;
  display: block;
}

.Footer {
  padding: 0.5rem;
  display: flex;
  position: absolute;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  bottom: 0;
  left: 0;
  right: 0;
}

@media screen and (max-width: 600px) {
  .App {
    padding: 4rem 2rem;
  }
}

@media screen and (max-width: 400px) {
  .App {
    padding: 4rem 1rem;
  }
}
/*
@media screen and (max-width: 1000px) {
  .ui.menu .item .header {
    visibility: hidden;
  }
  .ui.menu .item .header .icon {
    display: inline !important;
    visibility: visible !important;
  }
}
*/

/* Cookie Banner Styles */
.cookie-banner {
  position: fixed;
  bottom: 0.5rem;
  left: 1rem;
  right: 1rem;
  background-color: #f0ebeb;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 9999;
  padding: 1rem;
  border-radius: 0.75rem;
}

@media (min-width: 768px) {
  .cookie-banner {
    left: 2rem;
    right: 2rem;
    padding: 1.5rem;
  }
}

.cookie-banner-container {
  max-width: 80rem;
  margin: 0 auto;
}

.cookie-banner-main {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
}

@media (min-width: 768px) {
  .cookie-banner-main {
    flex-direction: row;
  }
}

.cookie-banner-content {
  flex: 1;
}

.cookie-banner-title {
  font-size: 1.125rem;
  font-weight: 600;
  margin-bottom: 0.25rem;
}

.cookie-banner-description {
  font-size: 0.875rem;
  color: #666;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.cookie-banner-buttons {
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
}

.cookie-settings {
  margin-bottom: 1rem;
}

.cookie-setting-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.cookie-setting-content h4 {
  font-weight: 500;
}

.cookie-setting-content p {
  font-size: 0.875rem;
  color: #666;
}

.btn {
  padding: 0.5rem 1rem;
  border-radius: 0.375rem;
  cursor: pointer;
  font-weight: 500;
}

.btn-outline {
  border: 1px solid #ccc;
  background-color: transparent;
}

.btn-primary {
  background-color: #F17221;
  color: white;
}

.checkbox {
  width: 1.25rem;
  height: 1.25rem;
  border: 1px solid #ccc;
  border-radius: 0.25rem;
}

.congratulations-header {
  margin: 2rem 0;
  padding: 1rem;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.05);
}

.buyer-name {
  color: #f2711c; /* Orange Farbe für den Namen des Schenkenden */
  font-weight: bold;
}

.gift-selection-container {
  max-width: 900px;
  margin: 2rem auto;
}

.gift-showcase {
  padding: 2rem !important;
  background-color: #fff !important;
  box-shadow: 0 4px 12px rgba(0,0,0,0.08) !important;
}

.selection-title {
  margin-bottom: 2rem !important;
  color: #333;
}

.gift-images-container {
  margin: 1.5rem 0;
}

.gift-images-container img {
  transition: transform 0.3s ease;
  cursor: pointer;
  border: 2px solid transparent;
}

.gift-images-container img:hover {
  transform: scale(1.05);
  border-color: #f2711c;
}

.code-selection-area {
  margin-top: 2.5rem;
  text-align: center;
}

.code-display {
  margin-bottom: 1.5rem !important;
  padding: 0.8rem 1.2rem !important;
  font-size: 1.2rem !important;
}

.choose-present-button {
  margin-top: 1rem !important;
  padding: 1rem 2rem !important;
  transition: background-color 0.3s ease !important;
}

.choose-present-button:hover {
  background-color: #e05f00 !important;
}

.error-message {
  margin-top: 1rem !important;
  width: 100% !important;
  max-width: 500px !important;
  margin-left: auto !important;
  margin-right: auto !important;
}

.app-footer {
  margin-top: 3rem;
  padding: 2rem 0;
  background-color: #f8f8f8;
  border-top: 1px solid #eaeaea;
}

.footer-links .item {
  margin: 0 1rem !important;
}

@media (max-width: 768px) {
  .congratulations-header {
    margin: 1rem 0;
  }
  
  .gift-showcase {
    padding: 1rem !important;
  }
  
  .code-display {
    font-size: 1rem !important;
  }
}

.gift-icon-container {
  padding: 3rem 2rem !important;
  text-align: center;
  max-width: 500px;
  margin: 2rem auto !important;
  transition: transform 0.3s ease;
  position: relative;
}

.gift-icon-wrapper {
  cursor: pointer;
  margin: 2rem auto;
  padding: 2rem;
  border-radius: 50%;
  background-color: #fffaf7;
  display: inline-block;
  transition: all 0.3s ease;
  border: 2px dashed #f2711c;
}

.gift-icon-wrapper:hover {
  transform: scale(1.05);
  background-color: #fff0e6;
  box-shadow: 0 5px 15px rgba(242, 113, 28, 0.2);
}

.gift-icon-text {
  margin-top: 1rem;
  font-size: 1.3rem;
  color: #f2711c;
  font-weight: bold;
}

.gift-icon-pulse {
  animation: pulse 2s infinite;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

.code-label-container {
  margin-top: 1.5rem;
  padding-top: 1rem;
  border-top: 1px dashed #f2711c30;
}

.code-label {
  font-size: 1.1rem !important;
  padding: 0.8rem 1.2rem !important;
  box-shadow: 0 2px 4px rgba(0,0,0,0.05) !important;
}

/* Modern UI Styles for BuyCode component */
.modern-ui {
  background-color: #ffffff;
  min-height: 100vh;
  position: relative;
  padding-bottom: 4rem;
  padding-top: 80px !important;
}

.category-menu {
  border-radius: 12px !important;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05) !important;
  overflow: hidden;
  margin-bottom: 2rem !important;
  background-color: #fdfdfd !important;
}

.category-item {
  padding: 1.2rem 2rem !important;
  transition: all 0.3s ease !important;
  position: relative;
}

.category-item:hover {
  background-color: #f9f9f9 !important;
  transform: translateY(-2px);
}

.active-category {
  background-color: #fff8f3 !important;
  box-shadow: inset 0 -3px 0 #F17221;
}

.star-container {
  display: flex;
  justify-content: center;
  color: #F17221;
}

.floating-purchase-container {
  position: fixed;
  bottom: 1.5rem;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  z-index: 1000;
}

.purchase-button {
  box-shadow: 0 4px 12px rgba(241, 114, 33, 0.3) !important;
  border-radius: 30px !important;
  padding-left: 2rem !important;
  padding-right: 2rem !important;
  transform: translateY(0);
  transition: all 0.3s ease !important;
  position: relative;
}

.purchase-button:hover {
  transform: translateY(-3px);
  box-shadow: 0 6px 15px rgba(241, 114, 33, 0.4) !important;
}

.modern-footer {
  margin-top: 3rem;
  padding: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-top: 1px solid #f0f0f0;
}

.modern-footer .list {
  margin-bottom: 1rem !important;
}

.modern-footer .list .item {
  color: #555 !important;
  transition: color 0.2s ease;
}

.modern-footer .list .item:hover {
  color: #F17221 !important;
}

.social-links {
  margin-top: 0.5rem;
}

.social-links button {
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1) !important;
  transition: transform 0.2s ease;
}

.social-links button:hover {
  transform: translateY(-2px);
}

@media (max-width: 950px) {
  .category-item {
    padding: 1rem !important;
  }

  .purchase-button {
    width: 90%;
    max-width: 300px;
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .modern-footer {
    padding: 1rem;
  }

  /* Additional card fixes for mobile */
  .ui.cards {
    margin-left: -0.5rem !important;
    margin-right: -0.5rem !important;
  }
  
  .ui.cards > .card {
    min-width: 280px !important;
    margin: 0.5rem !important;
    width: calc(100% - 1rem) !important;
    flex: 0 0 calc(100% - 1rem) !important;
  }
}

/* Fix for card width to ensure consistent sizing across rows and center alignment */
.equal-width-cards.ui.cards {
  display: flex !important;
  flex-wrap: wrap !important;
  justify-content: center !important;
  margin: -0.5em -0.5em !important;
  width: 100% !important;
}

.equal-width-cards.ui.cards > .card {
  flex: 0 0 calc(25% - 1em) !important;
  max-width: calc(25% - 1em) !important;
  min-width: 250px !important;
  margin: 0.5em !important;
  width: calc(25% - 1em) !important;
}

@media (max-width: 1400px) {
  .equal-width-cards.ui.cards > .card {
    flex: 0 0 calc(33.33% - 1em) !important;
    max-width: calc(33.33% - 1em) !important;
    width: calc(33.33% - 1em) !important;
  }
}

@media (max-width: 1100px) {
  .equal-width-cards.ui.cards > .card {
    flex: 0 0 calc(50% - 1em) !important;
    max-width: calc(50% - 1em) !important;
    width: calc(50% - 1em) !important;
  }
}

@media (max-width: 650px) {
  .equal-width-cards.ui.cards > .card {
    flex: 0 0 calc(100% - 1em) !important;
    max-width: calc(100% - 1em) !important;
    width: calc(100% - 1em) !important;
  }
}

/* Ensure the cards take up the full available width */
.equal-width-cards.ui.cards {
  display: flex !important;
  flex-wrap: wrap !important;
  justify-content: center !important;
  margin: -0.5em -0.5em !important;
  width: 100% !important;
}

/* Adjust container padding on smaller screens */
@media (max-width: 1200px) {
  .Container_Modal {
    padding: 1.5rem;
    width: 98% !important;
  }
}

/* Blur-Effekt für den Bereich unter dem Kaufbutton */
.modern-ui::after {
  content: '';
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: 4rem; /* Höhe des Blur-Effekts */
  background: linear-gradient(to bottom, rgba(255,255,255,0) 0%, rgba(255,255,255,0.6) 50%, rgba(255,255,255,0.8) 100%);
  pointer-events: none; /* Ermöglicht Klicks auf Elemente darunter */
  z-index: 999; /* Unter dem Button, aber über anderen Inhalten */
}

/* Anpassung der floating-purchase-container, damit der Button über dem Blur liegt */
.floating-purchase-container {
  position: fixed;
  bottom: 1.5rem;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  z-index: 1000; /* Höherer z-index als der Blur-Effekt */
}

.purchase-button {
  box-shadow: 0 4px 12px rgba(241, 114, 33, 0.3) !important;
  border-radius: 30px !important;
  padding-left: 2rem !important;
  padding-right: 2rem !important;
  transform: translateY(0);
  transition: all 0.3s ease !important;
  position: relative; /* Stellt sicher, dass der Button über dem Blur liegt */
}

/* Modern Navbar Styles */
.navbar-container {
  width: 100%;
}

.modern-navbar {
  background-color: white !important;
  box-shadow: 0 3px 15px rgba(0, 0, 0, 0.07) !important;
  height: 72px !important;
  display: flex !important;
  align-items: center !important;
  border: none !important;
  padding: 0 !important;
}

.desktop-menu-container {
  display: flex;
  width: 100%;
  max-width: 1400px;
  margin: 0 auto;
  padding: 0 20px;
  align-items: center;
  justify-content: space-between;
}

.desktop-navbar {
  padding: 0 !important;
}

.logo-container {
  padding: 0.5rem 1rem !important;
  margin-right: 1rem !important;
}

.mobile-logo {
  margin: 0 !important;
}

.nav-links {
  display: flex;
  align-items: center;
  margin-left: auto;
}

.nav-item {
  margin: 0 0.5rem !important;
  border-radius: 6px !important;
  transition: all 0.2s ease !important;
  padding: 0.8rem 1.2rem !important;
  font-weight: 600 !important;
}

.nav-item:hover {
  background-color: #f8f8f8 !important;
  transform: translateY(-2px);
}

.active-nav-item {
  background-color: #fff8f3 !important;
  color: #F17221 !important;
}

.active-nav-item:hover {
  background-color: #fff0e6 !important;
}

.b2b-button {
  margin-left: 1rem !important;
  border-radius: 30px !important;
  padding: 0.8rem 1.4rem !important;
  box-shadow: 0 3px 10px rgba(234, 60, 60, 0.15) !important;
  transition: all 0.2s ease !important;
  font-weight: 600 !important;
}

.b2b-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 5px 12px rgba(234, 60, 60, 0.25) !important;
}

.language-container {
  margin-left: 1.5rem;
  display: flex;
  align-items: center;
}

.language-dropdown.desktop {
  padding: 0.7rem !important;
  border-radius: 50% !important;
  transition: all 0.2s ease !important;
  background-color: #f8f8f8 !important;
}

.language-dropdown.desktop:hover {
  background-color: #f0f0f0 !important;
  transform: translateY(-2px);
}

.lang-flag {
  margin: 0 !important;
}

.language-item {
  padding: 0.8rem 1.2rem !important;
  transition: all 0.15s ease !important;
}

.language-item:hover {
  background-color: #f8f8f8 !important;
}

/* Mobile menu styles */
.mobile-navbar {
  padding: 0 1rem !important;
}

.mobile-dropdown {
  margin-right: 0.5rem !important;
}

.modern-dropdown-menu {
  margin-top: 1rem !important;
  min-width: 200px !important;
  border-radius: 8px !important;
  border: none !important;
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.1) !important;
  overflow: hidden !important;
}

.modern-dropdown-item {
  padding: 1rem 1.5rem !important;
  display: flex !important;
  align-items: center !important;
  transition: all 0.15s ease !important;
}

.modern-dropdown-item:hover {
  background-color: #f8f8f8 !important;
}

.modern-dropdown-item .icon {
  margin-right: 0.8rem !important;
}

.active-text {
  color: #F17221 !important;
  font-weight: 700 !important;
}

.b2b-dropdown-item {
  margin-top: 0.2rem !important;
}

.b2b-text {
  color: #e74c3c !important;
  font-weight: 700 !important;
}

/* Alternative: Du könntest auch einen Container direkt unter der Navbar verwenden */
.content-container {
  margin-top: 80px;
}

/* Anpassung der Schriftgröße und -stärke für die MenuBar */
.nav-item,
.modern-dropdown-item span,
.b2b-button {
  font-weight: 600 !important; /* Bereits fetteren Text */
  font-size: 1.08rem !important; /* Größere Schrift */
}

.active-text,
.b2b-text {
  font-weight: 700 !important;
  font-size: 1.08rem !important;
}

/* Sicherstellen, dass die Schrift in den Headers auch größer ist */
.menu-item {
  font-weight: 600 !important;
  font-size: 1.08rem !important;
}

/* Anpassungen für mobile Version */
.mobile-navbar .modern-dropdown-item span {
  font-weight: 600 !important;
  font-size: 1.08rem !important;
}

/* B2B-Button Text etwas größer */
.b2b-button {
  font-size: 1.08rem !important;
}

/* Möglicherweise müssen Icon-Größen angepasst werden */
.nav-item .icon,
.modern-dropdown-item .icon,
.b2b-button .icon {
  font-size: 1.08rem !important; /* Etwas größere Icons passend zur größeren Schrift */
}

/* Spacer für die Navbar, damit Inhalte nicht verdeckt werden */
.navbar-spacer {
  height: 85px; /* Höhe der Navbar + etwas Abstand */
}

/* Klasse für den Content-Bereich unter der Navbar */
.content-with-navbar {
  padding-top: 100px !important;
}

/* Neue Styles für die überarbeitete Redeem-Seite */
.redeem-hero-section {
  background-color: #fff8f3;
  padding: 3rem 0;
  text-align: center;
  margin-bottom: 2rem;
  border-bottom: 1px solid #f0f0f0;
}

.redeem-heading {
  font-size: 2.5rem !important;
  color: #333 !important;
  margin-bottom: 0.5rem !important;
}

.redeem-subheading {
  font-size: 1.2rem;
  color: #666;
  max-width: 600px;
  margin: 0 auto;
}

.redeem-container {
  display: flex !important;
  flex-wrap: wrap;
  gap: 2rem;
}

/* Redemption Process Styles */
.redemption-steps {
  margin: 2rem auto 3rem !important;
  border: none !important;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05) !important;
  border-radius: 10px !important;
  overflow: hidden !important;
  background-color: #fdfdfd !important;
}

.redemption-steps .step {
  padding: 1.5rem 1rem !important;
  border-right: 1px solid #f0f0f0 !important;
}

.redemption-steps .step:hover {
  background-color: #fff8f3 !important;
}

.redemption-steps .active.step {
  background-color: #fff8f3 !important;
}

.redemption-steps .step .icon {
  font-size: 1.8rem !important;
  margin-bottom: 0.5rem !important;
}

.redemption-steps .step .title {
  font-size: 1.1rem !important;
  font-weight: 600 !important;
  margin-bottom: 0.3rem !important;
}

.redemption-steps .step .description {
  font-size: 0.9rem !important;
  color: #666 !important;
}

/* Code Entry Card Styles */
.code-entry-card {
  border: none !important;
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.05) !important;
  border-radius: 15px !important;
  overflow: hidden !important;
  margin-bottom: 3rem !important;
}

.code-entry-card .content {
  padding: 2rem !important;
}

.code-input-container, .captcha-container, .redeem-actions {
  margin-top: 2rem;
}

.input-label {
  font-size: 1.2rem !important;
  margin-bottom: 1rem !important;
  color: #333 !important;
}

.code-input input {
  height: 60px !important;
  font-size: 1.1rem !important;
  border-radius: 30px !important;
  border: 2px solid #f0f0f0 !important;
  transition: all 0.3s ease !important;
}

.code-input input:focus {
  border-color: #F17221 !important;
  box-shadow: 0 0 0 2px rgba(241, 114, 33, 0.1) !important;
}

.captcha-wrapper {
  display: flex;
  justify-content: center;
  margin: 1rem 0;
}

.redeem-actions {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 2.5rem;
}

.redeem-button, .thank-you-button {
  min-width: 200px !important;
  border-radius: 30px !important;
  font-weight: 600 !important;
  padding: 1rem 2rem !important;
  font-size: 1.1rem !important;
  margin: 0.5rem 0 !important;
  box-shadow: 0 4px 10px rgba(241, 114, 33, 0.2) !important;
  transition: all 0.3s ease !important;
}

.redeem-button:hover, .thank-you-button:hover {
  transform: translateY(-2px) !important;
  box-shadow: 0 6px 15px rgba(241, 114, 33, 0.3) !important;
}

.present-modal {
  border-radius: 15px !important;
  overflow: hidden !important;
}

/* Mobile Responsive Adjustments */
@media (max-width: 768px) {
  .redemption-steps {
    margin: 1.5rem auto 2rem !important;
  }
  
  .redemption-steps .step {
    padding: 1rem 0.5rem !important;
  }
  
  .redemption-steps .step .icon {
    font-size: 1.5rem !important;
  }
  
  .redemption-steps .step .title {
    font-size: 0.9rem !important;
  }
  
  .redemption-steps .step .description {
    display: none;
  }
  
  .code-entry-card .content {
    padding: 1.5rem !important;
  }
  
  .input-label {
    font-size: 1.1rem !important;
  }
  
  .code-input input {
    height: 50px !important;
  }
  
  .redeem-heading {
    font-size: 2rem !important;
  }
  
  .redeem-subheading {
    font-size: 1rem;
  }
}

/* Modern Button Styles for BuyerInfo.js */
.modern-button {
  border-radius: 30px !important;
  padding: 0.8rem 1.5rem !important;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1) !important;
  transition: all 0.3s ease !important;
  font-weight: 600 !important;
  margin: 0.5rem !important;
}

.modern-button:hover {
  transform: translateY(-2px) !important;
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.15) !important;
}

.modern-button.ui.orange.button {
  box-shadow: 0 4px 10px rgba(241, 114, 33, 0.2) !important;
}

.modern-button.ui.orange.button:hover {
  box-shadow: 0 6px 15px rgba(241, 114, 33, 0.3) !important;
  background-color: #e05f00 !important;
}

.modern-button.secondary-button {
  background-color: #f8f8f8 !important;
  color: #333 !important;
}

.modern-button.secondary-button:hover {
  background-color: #f0f0f0 !important;
}

.modern-button.gray-button {
  background-color: #767676 !important;
  color: white !important;
}

.modern-button.gray-button:hover {
  background-color: #5a5a5a !important;
}

.modern-button.copied-button {
  background-color: #21ba45 !important;
  color: white !important;
}

.share-button-group {
  box-shadow: none !important;
}

.share-button-group .button {
  margin: 0.3rem !important;
}

/* Mobile responsive adjustments for button groups */
@media (max-width: 768px) {
  .share-button-group.vertical {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  
  .share-button-group.vertical .button {
    margin: 0.5rem 0 !important;
    width: 100%;
  }
  
  .Container_small_middle {
    flex-direction: column;
    width: 100%;
  }
  
  .Container_small_middle .button {
    width: 100%;
    margin: 0.5rem 0 !important;
  }
}

/* Link Copy Button Style */
.link-copy-button {
  background-color: #333333 !important;
  color: white !important;
  border-radius: 30px !important;
  padding: 0.8rem 1.5rem !important;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1) !important;
  transition: all 0.3s ease !important;
  font-weight: 600 !important;
  margin: 0.5rem !important;
}

.link-copy-button:hover {
  background-color: #222222 !important;
  transform: translateY(-2px) !important;
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.15) !important;
}

.link-copy-button:active {
  background-color: #888888 !important;
  transform: translateY(0) !important;
}

/* Für den Zustand, wenn der Text kopiert wurde */
.link-copy-button.text-copied {
  background-color: #21ba45 !important;
}

.modern-button.whatsapp-button {
  background-color: #25D366 !important;
  color: white !important;
}

.modern-button.whatsapp-button:hover {
  background-color: #128C7E !important;
}